import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Markdown from "react-markdown";

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiCorBlog(slug: { eq: $slug }) {
      strapiId
      Title
      Content
      subTitle
      createdAt
      description
      authorName
      authorUrl
      blogImg {
        publicURL
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiCorBlog;
  const seo = {
    metaTitle: `Illumination - ${article.Title}`,
    metaDescription: article.description,
    shareImage: article.blogImg,
    article: true,
  };

  return (
    <Layout seo={seo}>
      <div className="container blog-container">
        <h1 className="blog-title"><Link to={`/`}>Illumination</Link></h1>
        <h1 className="title">{article.Title}</h1>
        <h2 className="subtitle">{article.subTitle} - {article.createdAt}</h2>
        {article.authorName ? <h2 className="author">By <a className="author-name" href={article.authorUrl}>{article.authorName}</a></h2> : ''}
        <Img
          fixed={article.blogImg.childImageSharp.original}
          imgStyle={{ position: "static"}}
          className="main-img"
          />
        <Markdown source={article.Content} escapeHtml={false} />
      </div>
    </Layout>
  );
};

export default Article;